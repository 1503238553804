import React, {Fragment, Suspense, useCallback, useEffect, useState} from 'react'
import MenuComponent from "../utils/menu/MenuComponent";
import './BlogStyle.css';
import HeaderBlogsContentCenter from "./item/HeaderBlogsContentCenter";
import SearchBlogsContentLeft from "./item/SearchBlogsContentLeft";
import {FaChartLine, FaGoogleWallet} from "react-icons/fa";
import {BsBookmarks} from "react-icons/bs";
import RecentItem from "./item/RecentItem";
import {useParams} from "react-router-dom";
import ItemBlogList from "./item/ItemBlogList";
import {API_BASE_URL} from "../../config";
import InfiniteScroll from "react-infinite-scroll-component";
import Spinner2 from "../utils/sipnner/Spinner2";
import {useCookies} from "../../cookieContext";
import StructuredData from "../utils/StructuredData";
import {useSearchParams} from 'react-router-dom';
import {PiTrendUpThin} from "react-icons/pi";
import {FiUsers} from "react-icons/fi";
import {IoIosTrendingUp} from "react-icons/io";
import {GoHome} from "react-icons/go";
import {FloatingNav} from "../utils/BlogMenuBar";

const BlogsComponent = ({initialData}) => {
    const [searchParams] = useSearchParams();
    const tagNameParams = searchParams.get('tag');
    const query = searchParams.get('q');
    const {sortByParams} = useParams();
    const [blogsData] = useState(initialData || {});

    const [leftContentVisible, setLeftContentVisible] = useState(false);
    const [blogs, setBlogs] = useState([]);
    const [listTag, setListTag] = useState([]);
    const [listRecent, setListRecent] = useState([]);

    const [page, setPage] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(false);

    const [isMobile, setIsMobile] = useState(false);

    const {cookies} = useCookies();

    const usernameLocal = cookies.username
    const tokenLocal = cookies.token


    const navItems = [
        {
            name: "All",
            link: "/blogs/all",
            icon: <GoHome className="h-6 w-6 text-neutral-500 dark:text-white"/>,
        },
        {
            name: "Popular",
            link: "/blogs/popular",
            icon: <IoIosTrendingUp className="h-6 w-6 text-neutral-500 dark:text-white"/>,
        }
    ];

    const fetchUserRecent = useCallback(async () => {
        try {
            setLoading(true);
            const response = await fetch(`${API_BASE_URL}/api/v1/user/get/recent/${usernameLocal}`);
            const data = await response.json();
            setListRecent(data);
        } catch (e) {
            console.error('Error fetching data:', e);
        } finally {
            setLoading(false);
        }
    }, [usernameLocal]);

    const fetchTags = useCallback(async () => {
        try {
            setLoading(true);
            const response = await fetch(`${API_BASE_URL}/api/v1/blog-tag/get/top`);
            const data = await response.json();
            setListTag(data);
        } catch (e) {
            console.error('Error fetching data:', e);
        } finally {
            setLoading(false);
        }
    }, []);

    // const fetchData = useCallback(async (url) => {
    const fetchData = useCallback(async (url, isNewQuery = false) => {

        try {
            setLoading(true);
            let data;

            const res = await fetch(url);
            if (!res.ok) {
                setHasMore(false);
                throw new Error(`HTTP error! status: ${res.status}`);
            }
            data = await res.json();

            // if (blogs.length === 0 && blogsData?.blogsData?.page?.totalElements !== 0 && blogsData?.blogsData?._embedded?.blogListResponseList.length !== 0) {
            //     data = blogsData.blogsData;
            //     console.log("Data from initial data: ", data);
            // } else {
            //     const res = await fetch(url);
            //     if (!res.ok) {
            //         setHasMore(false);
            //         throw new Error(`HTTP error! status: ${res.status}`);
            //     }
            //     data = await res.json();
            //     console.log("Data from fetch data 00: ", data);
            // }

            // Simulate network delay (consider removing in production)
            await new Promise(resolve => setTimeout(resolve, 600));

            if (data._embedded?.blogListResponseList) {
                setBlogs(prevBlogs => {
                    if (isNewQuery) {
                        return data._embedded.blogListResponseList;
                    }
                    const newBlogs = data._embedded.blogListResponseList.filter(newBlog =>
                        !prevBlogs.some(existingBlog => existingBlog.id === newBlog.id)
                    );
                    return [...prevBlogs, ...newBlogs];
                });

                setHasMore(data.page?.number < data.page?.totalPages - 1);
            } else {
                setBlogs([]);
                setHasMore(false);
            }
        } catch (error) {
            console.error('Error fetching data:', error.message);
        } finally {
            setLoading(false);
        }
    }, [blogs]);

    useEffect(() => {
        const checkScreenSize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        checkScreenSize();
        window.addEventListener('resize', checkScreenSize);

        return () => window.removeEventListener('resize', checkScreenSize);
    }, []);

    useEffect(() => {
        void fetchUserRecent();
        void fetchTags();
    }, [fetchUserRecent, fetchTags]);

    useEffect(() => {
        const isNewQuery = page === 0;

        let url;
        if (sortByParams === 'popular') {
            url = `${API_BASE_URL}/api/v1/blog/get/by/popular?page=${page}&size=5`;
        } else if (sortByParams === 'all') {
            url = `${API_BASE_URL}/api/v1/blog/get/by/all?page=${page}&size=5`;
        } else if (tagNameParams !== null) {
            url = `${API_BASE_URL}/api/v1/blog/get/tag/${tagNameParams}?page=${page}&size=5`
        } else if (query) {
            url = `${API_BASE_URL}/api/v1/blog/get/search/${query}?page=${page}&size=5`;
        } else if (sortByParams === 'saved' && usernameLocal) {
            url = `${API_BASE_URL}/api/v1/blog/get/saved/${usernameLocal}?page=${page}&size=5`;
        } else if (sortByParams === 'following' && usernameLocal) {
            url = `${API_BASE_URL}/api/v1/blog/get/following/${usernameLocal}?page=${page}&size=5`;
        } else {
            url = `${API_BASE_URL}/api/v1/blog/get/by/all?page=${page}&size=5`;
        }

        void fetchData(url, isNewQuery);

    }, [sortByParams, page, tagNameParams, usernameLocal, query]);

    const structuredData = {
        "@context": "https://schema.org",
        "@type": "CollectionPage",
        "mainEntity": {
            "@type": "ItemList",
            "itemListElement": blogsData?.blogsData?._embedded?.blogListResponseList.slice(0, 10).map((blog, index) => ({
                "@type": "ListItem",
                "position": index + 1,
                "item": {
                    "@type": "BlogPosting",
                    "headline": blog.title,
                    "datePublished": blog.createdAt,
                    "dateModified": blog.createdAt,
                    description: blog.shortDescription,
                },
                "url": `https://w404.net/${blog.username}/blog/${blog.slug}`
            }))
        },
        "url": "https://w404.net/blogs",
        "name": "W404 Blog - The best blog for you to read, learn and share",
        "image": "https://w404.net/assets/images/background.png",
        "description": "The best blog for you to read, learn and share, with a lot of useful information and knowledge. Let's join us! Create amazing blogs and share your knowledge with the world!",
        "publisher": {
            "@type": "Organization",
            "name": "W404",
            "logo": {
                "@type": "ImageObject",
                "url": "https://w404.net/assets/images/logo1.png"
            }
        }
    };

    return (
        <Fragment>
            <StructuredData imageUrl="https://w404.net/assets/images/background.png"
                            data={structuredData} title={"W404 Blog"} url={"https://w404.net/blogs"}
                            description={"The best blog for you to read, learn and share, with a lot of useful information and knowledge. Let's join us! Create amazing blogs and share your knowledge with the world!"}/>
            <MenuComponent/>
            <div className="container">
                <div className="blogs-content grid grid-cols-12 blogs-body mb-5">
                    <div
                        className={leftContentVisible ? 'blogs-content-left active' : 'blogs-content-left'}>
                        <div className="blogs-content-left-menu pb-4">
                            <SearchBlogsContentLeft/>
                            <hr className="mb-6 sm:mx-auto border-[#a1a1aa23] lg:my-8"
                                style={{marginTop: "23px", borderTopWidth: "2px"}}/>
                            <a href={`/blogs/all`}
                               className={sortByParams === 'all' ? "blogs-btn-active" : "blogs-content-left-btn"}>
                                <FaGoogleWallet
                                    className={"blogs-content-left-btn-icon inline mr-2 origin-center hover:origin-top"}/>All
                            </a>
                            <a href={`/blogs/popular`}
                               className={sortByParams === 'popular' ? "blogs-btn-active" : "blogs-content-left-btn"}>
                                <FaChartLine className={"blogs-content-left-btn-icon inline mr-2"}/>Popular
                            </a>

                            {
                                usernameLocal && tokenLocal && (
                                    <Fragment>
                                        <a href={`/blogs/following`}
                                           className={sortByParams === 'following' ? "blogs-btn-active" : "blogs-content-left-btn"}>
                                            <FiUsers className={"blogs-content-left-btn-icon inline mr-2"}/>Following
                                        </a>
                                        <hr className="sm:mx-auto border-[#a1a1aa23] lg:my-8"
                                            style={{marginTop: "5px", marginBottom: "17px", borderTopWidth: "2px"}}/>
                                        <a href={`/blogs/saved`}
                                           className={sortByParams === 'saved' ? "blogs-btn-active" : "blogs-content-left-btn"}>
                                            <BsBookmarks className={"blogs-content-left-btn-icon inline mr-2"}/>Saved
                                        </a>
                                        <div className="blogs-content-left-recent w-full">
                                            <hr className="sm:mx-auto border-[#a1a1aa23] lg:my-8"
                                                style={{marginTop: "0px", marginBottom: "10px", borderTopWidth: "2px"}}/>
                                            <span className={"text-xl text-[#A1A1AA]"}>Recent</span>
                                            {
                                                listRecent.map((recent, index) => (
                                                    <RecentItem key={index} recentItem={recent}/>
                                                ))
                                            }
                                        </div>
                                    </Fragment>
                                )
                            }
                        </div>
                    </div>
                    {/*Blogs content center*/}
                    <div className="blogs-content-center col-span-7 h-fit px-2">
                        <div id={"scrollBlogs"}

                             className="grid grid-cols-1 gap-5 px-3 mt-5 h-screen overflow-y-scroll scrollbar-hide">
                            <div className="w-full py-6" style={{borderBottom: "0.1px solid #A1A1AA"}}>
                                <HeaderBlogsContentCenter/>
                            </div>


                            <Suspense fallback={<Spinner2/>}>

                                <InfiniteScroll
                                    dataLength={blogs.length}
                                    next={() => setPage((prev) => prev + 1)}
                                    hasMore={hasMore}
                                    loader={
                                        loading ? (
                                            <div className="w-full h-svh">
                                                <Spinner2/>
                                            </div>
                                        ) : null
                                    }
                                    endMessage={
                                        <p className="w-full text-center text-[#A1A1AA]">Yay! You have seen it all</p>
                                    }
                                    scrollableTarget={"scrollBlogs"}
                                    style={{display: 'flex', flexDirection: 'column', gap: '18px'}}
                                    className={"scrollbar-hide"}
                                >
                                    {blogs.map((blog, index) => (
                                        <ItemBlogList
                                            key={blog.id || index}
                                            shortDescription={blog.shortDescription}
                                            slug={blog.slug}
                                            title={blog.title}
                                            viewCount={blog.viewCount}
                                            imageUrl={blog.imageUrl}
                                            createdAt={blog.createdAt}
                                            username={blog.username}
                                            profileImageUrl={blog.profileImageUrl}
                                            displayName={blog.displayName}
                                            status={blog.status}
                                        />
                                    ))}


                                </InfiniteScroll>
                            </Suspense>
                            {isMobile && (
                                <FloatingNav className={"p-3"} sortByParams={sortByParams} navItems={navItems}
                                             suggestions={listTag}/>
                            )}
                        </div>
                    </div>
                    {/*Blogs content right*/}
                    <div className="blogs-content-right col-span-2 h-full py-3">
                    <div className="border w-full h-fit rounded-2xl py-3 px-2">
                            <span
                                className={"text-[17px] font-semibold text-[#F4F4F5] ml-4 mt-2"}> For you <PiTrendUpThin
                                className={"inline"}/></span>
                            <div className="grid grid-cols-1 gap-2 mt-4">
                                {
                                    listTag.map((tag, index) => (
                                        <a key={index} href={`/blogs?tag=${tag}`}
                                           className="w-full py-2 pl-4 rounded text-[#A1A1AA] hover:bg-[#a1a1aa13] transition ease-in-out duration-300">
                                            {tag}
                                        </a>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </Fragment>
    )

}

export default BlogsComponent
