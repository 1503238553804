import React, {Fragment, useCallback, useEffect, useRef, useState} from "react";
import {
    motion,
    AnimatePresence,
    useScroll,
    useMotionValueEvent,
} from "framer-motion";
import {GoSearch} from "react-icons/go";
import {IoClose} from "react-icons/io5";
import {useCookies} from "../../cookieContext";
import {FiUsers} from "react-icons/fi";
import {BsBookmarks} from "react-icons/bs";

const Dialog = ({isOpen, onClose, suggestions}) => {
    const [inputValue, setInputValue] = useState('');

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const onSubmit = useCallback(
        (e) => {
            e.preventDefault();
            if (inputValue.trim()) {
                const searchQuery = encodeURIComponent(inputValue.trim());
                window.location.href = `/blogs?q=${searchQuery}`;
                onClose(); // Close the dialog after navigating
            }
        },
        [inputValue, onClose] // Add onClose to dependencies
    );

    return (
        <AnimatePresence>
            {isOpen && (
                <motion.div
                    className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-[6000]"
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    exit={{opacity: 0}}
                >
                    <motion.div
                        className="bg-[#1a1a1a] text-white rounded-2xl w-[90%] max-w-md p-4 shadow-lg"
                        initial={{scale: 0.8}}
                        animate={{scale: 1}}
                        exit={{scale: 0.8}}
                    >
                        {/* Header with Close Button */}
                        <form onSubmit={onSubmit}>
                            <div className="flex justify-between items-center mb-4">
                                <div className="w-full flex items-center space-x-2">
                                    <GoSearch className="text-neutral-500"/>
                                    <input
                                        onChange={handleInputChange}
                                        value={inputValue}
                                        type="text"
                                        placeholder="Type to search..."
                                        className="bg-transparent outline-none text-white placeholder-neutral-500 w-full"
                                    />
                                </div>
                                <button
                                    type="button" // Prevent form submission on close button
                                    onClick={onClose}
                                    className="text-neutral-500 hover:text-white"
                                >
                                    <IoClose size={20}/>
                                </button>
                            </div>

                            {/* Suggestions */}
                            <div className={"text-center"}>
                                <p className="text-sm text-neutral-400 mb-2">Suggestions</p>
                                <ul className="space-y-2">
                                    {suggestions.map((item, index) => (
                                        <li
                                            key={index}
                                            className="px-3 py-2 rounded-2xl text-white/80 hover:bg-[#303031] hover:text-white duration-300 cursor-pointer"
                                        >
                                            <a className={"no-underline"} href={"/blogs?tag=" + item}>{item}</a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </form>
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>
    );
};

export const FloatingNav = ({navItems, className, suggestions, sortByParams}) => {
    const {cookies} = useCookies();

    const usernameLocal = cookies.username
    const tokenLocal = cookies.token

    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const [visible, setVisible] = useState(false);

    const scrollTargetRef = useRef(null);

    useEffect(() => {
        scrollTargetRef.current = document.getElementById("scrollBlogs");
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            const scrollElement = scrollTargetRef.current;
            if (scrollElement) {
                const scrollTop = scrollElement.scrollTop;
                const scrollHeight = scrollElement.scrollHeight;
                const clientHeight = scrollElement.clientHeight;
                const scrollPercentage = scrollTop / (scrollHeight - clientHeight);

                if (scrollPercentage < 0.06) {
                    setVisible(false);
                } else {
                    setVisible(scrollPercentage < 1);
                }
            }
        };

        const scrollElement = scrollTargetRef.current;
        if (scrollElement) {
            scrollElement.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (scrollElement) {
                scrollElement.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);

    const {scrollYProgress} = useScroll({
        target: scrollTargetRef,
        offset: ["start start", "end end"], // Adjust the offset values
    });

    useMotionValueEvent(scrollYProgress, "change", (current) => {
        if (typeof current === "number") {
            let direction = current - scrollYProgress.getPrevious();

            if (scrollYProgress.get() < 0.06) {
                setVisible(false);
            } else {
                if (direction < 0) {
                    setVisible(true);
                } else {
                    setVisible(false);
                }
            }
        }
    });

    return (
        <AnimatePresence mode="wait">
            {/* Floating Navigation */}
            <motion.div
                initial={{
                    opacity: 1,
                    y: -100,
                }}
                animate={{
                    y: visible ? 0 : -100,
                    opacity: visible ? 1 : 0,
                }}
                transition={{
                    duration: 0.2,
                }}
                className={`fixed bottom-1 inset-x-0 mx-auto flex ${usernameLocal && tokenLocal ? "w-8/12" : "w-[200px]"} max-w-4xl border border-white/[0.2] rounded-full bg-black/90 shadow-lg z-[50] px-4 py-2 items-center justify-between ${className}`}
            >
                {navItems.map((navItem, idx) => (
                    <a
                        key={`link=${idx}`}
                        href={navItem.link}
                        className={sortByParams === navItem.name ? "icon-button-menu-bar-active" : "icon-button-menu-bar"}
                    >
                        <span className="block sm:hidden">{navItem.icon}</span>
                        <span className="hidden sm:block text-sm">{navItem.name}</span>
                    </a>
                ))}

                {
                    usernameLocal && tokenLocal && (
                        <Fragment>
                            <a href={"/blogs/following"}
                               className={sortByParams === "following" ? "icon-button-menu-bar-active" : "icon-button-menu-bar"}
                            >
                                <span className="block sm:hidden">
                                    <FiUsers className="h-6 w-6 text-neutral-500 dark:text-white"/>
                                </span>
                                <span className="hidden sm:block text-sm">Following</span>
                            </a>
                            <a href={"/blogs/saved"}
                               className={sortByParams === "saved" ? "icon-button-menu-bar-active" : "icon-button-menu-bar"}
                            >
                                <span className="block sm:hidden">
                                    <BsBookmarks className="h-6 w-6 text-neutral-500 dark:text-white"/>                                </span>
                                <span className="hidden sm:block text-sm">Saved</span>
                            </a>
                        </Fragment>
                    )
                }

                <button
                    onClick={() => setIsDialogOpen(true)}
                    className="relative text-neutral-50 items-center flex hover:bg-[#30303171] transform duration-300 rounded-full p-2"
                >
          <span className="block sm:hidden">
            <GoSearch className="h-6 w-6 text-neutral-500 dark:text-white"/>
          </span>
                    <span className="hidden sm:block text-sm">Search</span>
                </button>
            </motion.div>

            {/* Dialog Component */}
            <Dialog suggestions={suggestions} isOpen={isDialogOpen} onClose={() => setIsDialogOpen(false)}/>
        </AnimatePresence>
    );
};
