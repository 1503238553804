import React, { useMemo, useState, useEffect } from 'react';
import { AnimatedGradientText } from "../../ui/animated-gradient-text";
import { MdChevronRight } from "react-icons/md";
import { cn } from "../../lib/utils";
import { Button, Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import { IoSettingsOutline } from "react-icons/io5";
import Spinner2 from "./sipnner/Spinner2";
import markdownit from 'markdown-it'
import { API_BASE_URL } from "../../config";
import {decodeAPIKey, modelAI} from "./Encrypt";
import { useCookies } from "../../cookieContext";
import {useNavigate} from "react-router-dom";
import createDOMPurify from "dompurify";

const SummaryContent = ({ title, author, content }) => {
    const { cookies } = useCookies()
    const navigate = useNavigate();

    const tokenLocal = cookies.token;
    const usernameLocal = cookies.username;
    const [isOpen, setIsOpen] = useState(false)

    const [summary, setSummary] = useState('');
    const [aiSummary, setAiSummary] = useState(null);
    const [summaryText, setSummaryText] = useState('');

    const md = useMemo(() => markdownit(), []);

    let DOMPurify;

    if (typeof window === 'undefined') {
        // Server-side
        const jsdomModule = eval("require('jsdom')");
        const {JSDOM} = jsdomModule;
        const window = new JSDOM('').window;
        DOMPurify = createDOMPurify(window);
    } else {
        // Client-side
        DOMPurify = createDOMPurify(window);
    }

    const sanitizedHTML = DOMPurify.sanitize(summary);

    useEffect(() => {
        if (tokenLocal && usernameLocal) {
            setTimeout(()=> fetchAISummary(), 600);
        }
    }, [tokenLocal, usernameLocal]);

    const toggleOpen = () => {
        if (!tokenLocal || !usernameLocal) {
            alert('Please login to use this feature.');
            setTimeout(() => navigate('/login'), 1000);
            return;
        }

        const currentTime = Date.now();
        const oneMinute = 60 * 1000; // 60 seconds in milliseconds

        // Retrieve stored data from localStorage
        const storedData = JSON.parse(localStorage.getItem('summaryClickData') || '{}');
        const lastClickTime = storedData.lastClickTime || 0;
        const clickCount = storedData.clickCount || 0;

        if (currentTime - lastClickTime > oneMinute) {
            // Reset click count if more than a minute has passed
            localStorage.setItem('summaryClickData', JSON.stringify({
                lastClickTime: currentTime,
                clickCount: 1
            }));
        } else if (clickCount >= 2) {
            alert('You clicked too fast. Please wait a little and try again.');
            return;
        } else {
            // Increment click count
            localStorage.setItem('summaryClickData', JSON.stringify({
                lastClickTime: currentTime,
                clickCount: clickCount + 1
            }));
        }

        void generateSummary();
        setIsOpen(true);
    }

    const toggleClose = () => {
        setIsOpen(false)
    }

    const fetchAISummary = async () => {
        try {

            const response = await fetch(`${API_BASE_URL}/api/v1/ai-summary/get/default/${usernameLocal}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${tokenLocal}`
                }
            })

            const data = await response.json();
            setAiSummary(data);
        } catch (error) {
            setAiSummary(null);
        }
    }

    const generateSummary = async () => {
        try {
            if (tokenLocal && usernameLocal) {
                setSummary('');
                setSummaryText('');

                let response

                if (!aiSummary) {
                    // Use OpenRouter.ai for AI summarization
                    response = await fetch("https://openrouter.ai/api/v1/chat/completions", {
                        method: "POST",
                        headers: {
                            "Authorization": `Bearer ${decodeAPIKey(modelAI.AI_KEY)}`,
                            "HTTP-Referer": "https://w404.net", // Optional. Site URL for rankings on openrouter.ai.
                            "X-Title": "w404.net", // Optional. Site title for rankings on openrouter.ai.
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({
                            temperature: 0.1,
                            stream: true,
                            model: modelAI.AI_MODEL_NAME,
                            max_tokens: 250,
                            messages: [
                                {
                                    role: "user",
                                    content: `Summarize the following: \n\n Blog Title: ${title} \n\n Blog Author: ${author} \n\n Blog Content:${content}`
                                },
                                {
                                    role: "system",
                                    content: modelAI.AI_PROMPT_SUMMARY
                                }
                            ]
                        })
                    });
                } else {
                    // Use custom AI model for AI summarization
                    response = await fetch(`${aiSummary.endpoint}`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${decodeAPIKey(aiSummary.apiKey)}`
                        },
                        body: JSON.stringify({
                            temperature: 0.1,
                            stream: true,
                            model: `${aiSummary.modelName}`,
                            max_tokens: 250,
                            messages: [
                                {
                                    role: "system",
                                    content: `${aiSummary.prompt}`
                                },
                                {
                                    role: "user",
                                    content: `Summarize the following: \n\n Blog Title: ${title} \n\n Blog Author: ${author} \n\n Blog Content:${content}`
                                }
                            ]
                        })
                    });
                }

                const reader = response.body.getReader();
                const decoder = new TextDecoder();
                let buffer = '';

                while (true) {
                    const { done, value } = await reader.read();
                    if (done) break;

                    buffer += decoder.decode(value, { stream: true });
                    const lines = buffer.split('\n');
                    buffer = lines.pop();

                    for (const line of lines) {
                        if (line.startsWith('data: ')) {
                            const jsonData = line.slice(6);
                            if (jsonData.trim() === '[DONE]') continue;
                            try {
                                const parsedData = JSON.parse(jsonData);
                                const content = parsedData.choices[0]?.delta?.content || '';
                                setSummaryText(prev => {
                                    const newText = prev + content;
                                    setSummary(md.render(newText));
                                    return newText;
                                });
                            } catch (e) {
                                console.error('Error parsing JSON:', e);
                            }
                        }
                    }
                }
            }
        } catch (error) {
            console.error('Error generating summary:', error);
            setSummary('Failed to generate summary. Please try again later.');
        }
    };

    return (
        <div className={"summary-content"}>
            <div className="z-10 flex items-start justify-items-start">
                <AnimatedGradientText className={"cursor-pointer"} onClick={toggleOpen}>
                    🤖 <hr className="mx-2 h-4 w-px shrink-0 bg-gray-300"/>{" "}
                    <span
                        className={cn(
                            `inline animate-gradient bg-gradient-to-r from-[#ffaa40] via-[#9c40ff] to-[#ffaa40] bg-[length:var(--bg-size)_100%] bg-clip-text text-transparent`,
                        )}
                    >
                        Magic Summary
                    </span>
                    <MdChevronRight
                        className="ml-1 size-3 transition-transform duration-300 ease-in-out group-hover:translate-x-0.5"/>
                </AnimatedGradientText>
            </div>

            <Dialog open={isOpen} as="div" className="relative z-10 focus:outline-none" onClose={toggleClose}>
                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4">
                        <DialogPanel
                            transition
                            className="w-full max-w-md rounded-xl bg-white/5 p-6 backdrop-blur-2xl duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0"
                        >
                            <DialogTitle as="h3" className="text-base/7 font-medium text-white flex justify-between">
                                <div
                                    className="inline animate-gradient bg-gradient-to-r from-[#ffaa40] via-[#9c40ff] to-[#ffaa40] bg-[length:var(--bg-size)_100%] bg-clip-text text-transparent">
                                    AI Magic Summary
                                </div>

                                <a href={`/user/${usernameLocal}`}>
                                    <IoSettingsOutline/>
                                </a>
                            </DialogTitle>

                            <div className="mt-4">
                                {
                                    summary ? (
                                        <div
                                            className="text-white/80 text-sm/6"
                                            dangerouslySetInnerHTML={{__html: sanitizedHTML}}
                                        ></div>
                                    ) : (
                                        <Spinner2/>
                                    )
                                }
                            </div>

                            <div className="mt-4 flex items-end justify-end">
                                <Button
                                    className="inline-flex items-center gap-2 rounded-md bg-gray-700 py-1.5 px-3 text-sm/6 font-semibold text-white shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-gray-600 data-[focus]:outline-1 data-[focus]:outline-white data-[open]:bg-gray-700"
                                    onClick={toggleClose}
                                >
                                    Got it, thanks!
                                </Button>
                            </div>
                        </DialogPanel>
                    </div>
                </div>
            </Dialog>
        </div>
    );
}

export default SummaryContent;