import React, {Fragment, useCallback, useEffect, useState} from 'react';
import MenuComponent from "../../utils/menu/MenuComponent";
import FooterComponent from "../../utils/footer/FooterComponent";
import HeaderDetail from "./item/HeaderDetail";
import './PlaygroundDetailComponent.css';
import {Link, useNavigate, useParams} from "react-router-dom";
import {API_BASE_URL} from "../../../config";
import {MdOutlineEditNote} from "react-icons/md";
import {useCookies} from "../../../cookieContext";
import StructuredData, {truncateDescription} from "../../utils/StructuredData";
import IframeComponent from "./item/IframeComponent";
import {useCopyCode} from "../../utils/useCopyCode";

import createDOMPurify from "dompurify";

const PlaygroundDetailComponent = ({initialData}) => {
    const {slug, username} = useParams();
    const [playgroundDetail, setPlaygroundDetail] = useState(initialData?.exploreData || {});
    const [playgroundTags, setPlaygroundTags] = useState([]);

    const {cookies} = useCookies();
    const navigate = useNavigate();

    useCopyCode();

    const fetchData = useCallback(async (url, errorMessage) => {
        const response = await fetch(url);
        if (!response.ok) {
            navigate('/404');
            throw new Error(`${errorMessage}: ${response.status}`);
        }
        return response.json();
    }, []);

    const fetchPlaygroundDetail = useCallback(async () => {
        try {
            if (!playgroundDetail?.id) {
                const response = await fetchData(`${API_BASE_URL}/api/v1/playground/get/${username}/${slug}`, 'Error fetching explore detail');
                setPlaygroundDetail(response);
            }

            if (playgroundDetail?.id) {
                const tagResponse = await fetchData(`${API_BASE_URL}/api/v1/playground-tag/get/${playgroundDetail.id}`, 'Error fetching tags');
                setPlaygroundTags(tagResponse);
            }
        } catch (error) {
            console.error('Error fetching playground detail:', error);
            navigate('/404');
        }
    }, [username, slug, fetchData]);

    useEffect(() => {
        void fetchPlaygroundDetail();
    }, [fetchPlaygroundDetail]);

    let DOMPurify;

    if (typeof window === 'undefined') {
        // Server-side
        const jsdomModule = eval("require('jsdom')");
        const {JSDOM} = jsdomModule;
        const window = new JSDOM('').window;
        DOMPurify = createDOMPurify(window);
    } else {
        // Client-side
        DOMPurify = createDOMPurify(window);
    }

    const sanitizeHTMLMetaTag = (html) => ({
        __html: DOMPurify.sanitize(html, {ALLOWED_TAGS: []})
    });

    const structuredData = {
        "@context": "https://schema.org",
        "@type": "BlogPosting",
        "url": `https://w404.net/${username}/explore/${slug}`,
        "name": playgroundDetail.title,
        "description": truncateDescription(sanitizeHTMLMetaTag(playgroundDetail.description).__html),
        "author": {
            "@type": "Person",
            "name": username
        },
        "datePublished": playgroundDetail.createdAt,
        "dateModified": playgroundDetail.updatedAt,
        "image": playgroundDetail.imageUrl ? playgroundDetail.imageUrl : "https://w404.net/assets/images/background.png",
        "publisher": {
            "@type": "Organization",
            "name": "W404",
            "logo": {
                "@type": "ImageObject",
                "url": "https://w404.net/assets/images/logo1.png"
            }
        }
    };

    return (
        <Fragment>
            <StructuredData
                imageUrl={playgroundDetail.imageUrl ? playgroundDetail.imageUrl : "https://w404.net/assets/images/background.png"}
                data={structuredData} title={playgroundDetail.title}
                url={`https://w404.net/${username}/explore/${slug}`}
                description={sanitizeHTMLMetaTag(playgroundDetail.description).__html}
                item={{
                    author: playgroundDetail.user?.username,
                    datePublished: playgroundDetail.createdAt,
                    dateModified: playgroundDetail.updatedAt
                }}
            />
            <MenuComponent/>
            <div className={"container"}>
                <div className={"playground-detail-content mt-4 grid grid-cols-1 gap-4"}>
                    <div className="detail-title col-span-1 ">
                        {
                            cookies.token && playgroundDetail.user?.username === cookies.username && (
                                <div className="flex justify-end mb-2">
                                    <Link to={`/${playgroundDetail.user.username}/edit/explore/${playgroundDetail.slug}`}
                                          className="flex items-center text-[#A1A1AA] hover:border-b hover:border-[#F4F4F5] transform duration-300">
                                        <MdOutlineEditNote className="mr-1"/>
                                        Edit Blog
                                    </Link>
                                </div>
                            )
                        }
                        <HeaderDetail
                            username={username}
                            item={playgroundDetail}
                            fetchData={fetchData}
                            playgroundTags={playgroundTags}
                        />
                    </div>
                    <div className="mb-6">
                        {
                            playgroundDetail.iframe && (
                                <IframeComponent playgroundDetail={playgroundDetail}/>
                            )
                        }
                    </div>

                </div>
            </div>
            <FooterComponent/>
        </Fragment>
    );

}

export default PlaygroundDetailComponent; 