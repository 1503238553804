import CryptoJS from 'crypto-js';

let SECRET_KEY;
let AI_KEY;
let AI_MODEL_NAME;
let AI_PROMPT_SUMMARY;

if (typeof window !== 'undefined') {
    // Client-side
    SECRET_KEY = process.env.REACT_APP_SECRET_KEY;
    AI_KEY = process.env.REACT_APP_AI_KEY;
    AI_MODEL_NAME = process.env.REACT_APP_AI_MODEL_NAME;
    AI_PROMPT_SUMMARY = process.env.REACT_APP_AI_PROMPT_SUMMARY;
} else {
    // Server-side
    SECRET_KEY = 'my-secret-key';
    AI_KEY = "my-ai-key";
    AI_MODEL_NAME = "my-ai-model-name";
    AI_PROMPT_SUMMARY = "my-ai-prompt-summary";
}


if (!SECRET_KEY) {
    console.error('SECRET_KEY is not set in environment variables');
}

export const modelAI = {
    AI_KEY,
    AI_MODEL_NAME,
    AI_PROMPT_SUMMARY
};

export const encodeAPIKey = (data) => {
    try {
        return CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY).toString();
    } catch (error) {
        console.error('Encryption error:', error);
        return null;
    }
};

export const decodeAPIKey = (encryptedData) => {
    try {
        const bytes = CryptoJS.AES.decrypt(encryptedData, SECRET_KEY);
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    } catch (error) {
        console.error('Decryption error:', error);
        return null;
    }
};